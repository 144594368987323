.main-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to bottom, #e6e6fa, #fdc0d5);
}


.title {
  font-family: "Lobster", sans-serif;
  /* Choose an appropriate font */
  font-size: 48px;
  /* Adjust size as needed */
  color: #333333;
  /* Title text color */
}

.content {
  font-family: "Lobster", sans-serif;
  /* Choose an appropriate font */
  font-size: 24px;
  /* Adjust size as needed */
  color: #333333;
  /* Title text color */
}

.start-chat-button {
  background-color: #ff9caf; /* Your chosen palette color */
  color: white; /* White text */
  border: none; /* No border */
  font-size: 24px; /* Adjust size as needed */
  margin-top: 40px;
  padding: 15px 30px; /* Comfortable padding */
  border-radius: 30px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer; /* Cursor change to indicate it's clickable */
  text-align: center; /* Ensure text is centered */
  text-decoration: none; /* Remove underline if using an <a> tag */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  font-family: "lobster", cursive, sans-serif;
}

.start-chat-button:hover {
  background-color: #ff7ca1; /* Slightly darker shade for hover */
  transform: scale(1.05); /* Slightly scale the button */
}

.start-chat-button:active {
  transform: scale(0.95); /* Slightly shrink the button when clicked */
}

.cursor {
  animation: blink-animation 1s steps(5, start) infinite;
  opacity: 1;
}

@keyframes blink-animation {
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeInBounce 1.5s;
}

@keyframes fadeInBounce {
  0% { opacity: 0; transform: scale(0); }
  50% { opacity: 1; transform: scale(1.1); } /* Slightly larger */
  75% { transform: scale(0.9); } /* Slightly smaller */
  100% { transform: scale(1); } /* Back to normal size */
}