body {
    margin: 0;
}

.main-container {
    display: flex;
    flex-direction: column;
    /* Stack header and chat-box vertically */
    align-items: stretch;
    /* Stretch children to fit the container width */
    height: 100vh;
    /* Full height of the viewport */
    width: 100vw;
    overflow: hidden;
    /* Prevent overflow */
}

.header {
    display: flex;
    align-items: center;
    /* Align logo and title vertically */
    justify-content: center;
    /* Center the content */
    padding-top: 20px;
    /* Add some padding */
    background-color: #e6e6fa;
    /* Optional: background color for the header */
}

.logo {
    width: 60px;
    /* Adjust size as needed */
    height: auto;
    /* Maintain aspect ratio */
    margin-right: 0px;
    /* Space between logo and title */
}

.title {
    font-family: "Lobster", sans-serif;
    /* Choose an appropriate font */
    font-size: 28px;
    /* Adjust size as needed */
    color: #333333;
    /* Title text color */
}

.chat-box {
    flex-grow: 1;
    overflow-y: auto;
    width: 100vw;
    height: 80vh;
    margin: 0;
    background: linear-gradient(to bottom, #e6e6fa, #fdc0d5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.messages {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: linear-gradient(0deg, #ffd5e467, #e6e6fa);
    padding: 10px;
    border-radius: 10px;
}

.message {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-bottom: 10px;
    position: relative;
    border-radius: 25px;
    /* Rounded corners */
    padding: 10px 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
        inset 0px 0.7px 0px 0px rgba(255, 255, 255, 0.5);
    /* Inner shadow for bulge */
    font-family: "Agbalumo", cursive, sans-serif;
    /* Playful font */
}

/* Styling for avatars remains the same */

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.text {
    padding: 10px 15px;
    border-radius: 18px;
    box-shadow: 0 3px 6px #00000033,
        inset 0px 1.4px 0px 0px rgba(255, 255, 255, 0.4);
    color: white;
}

.sent {
    justify-content: flex-end;
}

.sent .text {
    background-color: #93cbe1;
    color: #ffffff;
}

.sent .avatar {
    margin-left: 10px;
}

.received {
    justify-content: flex-start;
}

.received .text {
    background-color: #fdc0d5;
    color: #f04e87;
}

.received .avatar {
    margin-right: 10px;
}

@keyframes rotateAndBump {
    0%, 100% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(360deg) scale(1.2);
    }
  }
  
  .loading-animation {
    width: 20px;
    height: 20px;
    animation: rotateAndBump 4s infinite;
    /* Add other styling as needed, e.g., size of the SVG */
  }

.input-container {
    display: flex;
    /* Enables Flexbox */
    justify-content: space-between;
    /* Spreads the children across the container */
    align-items: center;
    /* Aligns children vertically in the center */
    padding: 10px;
    /* Adds some padding inside the container */
}

.message-input {
    width: calc(100% - 60px);
    /* Adjust width to leave space for the send button */
    padding: 10px 15px;
    /* Comfortable padding */
    border: 2px solid #fdc0d5;
    /* Border color matching the sky blue theme */
    border-radius: 20px;
    /* Rounded corners */
    margin-right: 10px;
    /* Spacing between input field and button */
    font-family: "Agbalumo", cursive, sans-serif;
    /* Matching font */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    outline: none;
    /* Removes the default focus outline */
    flex-grow: 1;
    /* Allows the input to grow and fill available space */
    margin-right: 10px;
    /* Adds margin between input and button */
}

.message-input:focus {
    border-color: #93cbe1;
    /* Changes border color on focus to light pink */
}

.send-button {
    width: 50px;
    /* Fixed width */
    padding: 10px;
    /* Adjust padding for vertical alignment */
    line-height: 20px;
    /* Line height to center text vertically */
    text-align: center;
    /* Ensures horizontal alignment */
    background-color: #ff9caf;
    /* Vibrant pink */
    color: white;
    border: none;
    border-radius: 20px;
    font-family: "lobster", cursive, sans-serif;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.send-button:hover {
    background-color: #fdc0d5;
    /* Lighter pink on hover */
}